import Long from "long";

export const getDateFromIot = (
  ...secondsList: Array<number | undefined | null | Long.Long | string>
): Date | null => {
  const formattedSecondsList = secondsList.map((seconds) => {
    let formattedSeconds;

    if (typeof seconds === "string") {
      formattedSeconds = Number(seconds);
    } else if (seconds instanceof Long) {
      formattedSeconds = seconds.toNumber();
    } else {
      formattedSeconds = seconds || 0;
    }
    return formattedSeconds;
  });

  const greaterSeconds = Math.max(...formattedSecondsList);

  if (!greaterSeconds) {
    return null;
  } else {
    return new Date(greaterSeconds * 1000);
  }
};

export const isDateDifferenceExcessive = (
  date: Date | null,
  key: "last_state" | "last_online"
): boolean => {
  if (!date) {
    return true;
  }
  if (key === "last_online") {
    const tenMinutesInMilliseconds = 600_000;
    return new Date().getTime() - date.getTime() > tenMinutesInMilliseconds;
  } else {
    const dayInMilliseconds = 86_400_000;
    return new Date().getTime() - date.getTime() > dayInMilliseconds;
  }
};
